// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
function FindOrCreateApp() {
    if (getApps().length === 0) {
        // Your web app's Firebase configuration
        var firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        };
        return initializeApp(firebaseConfig);
    }
    else {
        return getApp();
    }
}
// Initialize Firebase
export var app = FindOrCreateApp(); //initializeApp(firebaseConfig);
