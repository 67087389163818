var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './CustomSlider.css';
import React from 'react';
var CustomSlider = function (_a) {
    var label = _a.label, value = _a.value, setValue = _a.setValue, _b = _a.vertical, vertical = _b === void 0 ? false : _b;
    return (_jsx("div", __assign({ className: "flex items-center w-full mt-2 mb-2 h-6 ".concat(vertical ? 'flex-col' : '') }, { children: _jsx("label", __assign({ htmlFor: "".concat(label, "Slider"), className: "select-none ".concat(vertical ? '' : 'w-64') }, { children: label })) })));
};
export default CustomSlider;
