var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect } from "react";
import { useSession } from "../../Sessions/SessionContext";
import { isEqual } from "lodash";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import VirtualPatientStateStorage from "../../../components/Database/VirtualPatientStateStorage";
import { useAppContext } from "../../../AppContext";
import { UE5MessageType } from "../../../components/ue5";
export function useCinemedicInUnrealHook_Private() {
    var _a = useSession(), rawSessionData = _a.rawSessionData, writeRawDataToSession = _a.writeRawDataToSession, sessionId = _a.sessionId;
    var broadcastMessage = useAppContext().broadcastMessage;
    // const [cinemedicSessionData, setCinemedicSessionData] = React.useState<CinemedicSessionData | undefined>(undefined);
    var cinemedicSessionDataRef = React.useRef(undefined);
    var setVirtualPatientState = useVirtualPatientStore.getState().setVirtualPatientState;
    React.useEffect(function () {
        if (!rawSessionData || !rawSessionData.data || !rawSessionData.data.virtualPatientState) {
            return;
        }
        var cinemedicSessionData = rawSessionData.data;
        if (isEqual(cinemedicSessionDataRef.current, cinemedicSessionData)) {
            return;
        }
        // setCinemedicSessionData(cinemedicSessionData);
        cinemedicSessionDataRef.current = cinemedicSessionData;
        var remoteVirtualPatientState = cinemedicSessionData.virtualPatientState;
        var localVirtualPatientState = useVirtualPatientStore.getState().virtualPatientState;
        if (!isEqual(remoteVirtualPatientState, localVirtualPatientState)) {
            setVirtualPatientState(remoteVirtualPatientState);
            console.log("Setting virtual patient state from remote data");
            console.log("Local (old):", localVirtualPatientState);
            console.log("Remote (new):", remoteVirtualPatientState);
        }
    }, [rawSessionData, setVirtualPatientState]);
    var initialiseDefaultSession = React.useCallback(function (preset) {
        var cinemedicSessionData = {
            rootDocId: preset.id,
            currentStateName: "Initial Presentation",
            docId: preset.id,
            virtualPatientState: preset.virtualPatientState,
        };
        cinemedicSessionDataRef.current = cinemedicSessionData;
        writeRawDataToSession(cinemedicSessionData);
    }, [writeRawDataToSession]);
    useEffect(function () {
        if (!sessionId || !rawSessionData) {
            return;
        }
        var cinemedicSessionData = rawSessionData.data;
        if (!cinemedicSessionData || !cinemedicSessionData.virtualPatientState) {
            console.log("No virtual patient state found in session data");
            var presetId_1 = "Cyclist";
            VirtualPatientStateStorage.getPreset(presetId_1).then(function (preset) {
                if (preset) {
                    initialiseDefaultSession(preset);
                    console.log("Set session ".concat(sessionId, " to default VP state (").concat(presetId_1, ")"), preset);
                }
                else {
                    console.error("Couldn't set session to default VP state (VP not found):", presetId_1);
                }
            });
        }
    }, [sessionId, rawSessionData, initialiseDefaultSession]);
    var setAvailableSpeech = React.useCallback(function (speechData) {
        if (!cinemedicSessionDataRef.current) {
            console.error("No session data available");
            return "No session data available";
        }
        var newCinemedicSessionData = __assign(__assign({}, cinemedicSessionDataRef.current), { availableSpeech_old: speechData });
        writeRawDataToSession(newCinemedicSessionData);
    }, [writeRawDataToSession]);
    var updateDeviceState = React.useCallback(function (updater) {
        if (!cinemedicSessionDataRef.current) {
            console.error("No session data available");
            return;
        }
        var currentDeviceState = cinemedicSessionDataRef.current.virtualPatientState.deviceStatus;
        var newDeviceState = updater(currentDeviceState);
        var newCinemedicSessionData = __assign(__assign({}, cinemedicSessionDataRef.current), { virtualPatientState: __assign(__assign({}, cinemedicSessionDataRef.current.virtualPatientState), { deviceStatus: newDeviceState }) });
        writeRawDataToSession(newCinemedicSessionData);
    }, [writeRawDataToSession]);
    useEffect(function () {
        if (!rawSessionData || !rawSessionData.data) {
            return;
        }
        var cinemedicSessionData = rawSessionData.data;
        if (cinemedicSessionData.hybridControlData) {
            broadcastMessage(UE5MessageType.updateHybridControlState.valueOf(), cinemedicSessionData.hybridControlData);
        }
    }, [rawSessionData, broadcastMessage]);
    useEffect(function () {
        if (!rawSessionData || !rawSessionData.data) {
            return;
        }
        var cinemedicSessionData = rawSessionData.data;
        if (cinemedicSessionData.systemControlState) {
            broadcastMessage(UE5MessageType.setEndScreenVisibility.valueOf(), cinemedicSessionData.systemControlState);
        }
    }, [rawSessionData, broadcastMessage]);
    return {
        initialiseDefaultSession: initialiseDefaultSession,
        setAvailableSpeech: setAvailableSpeech,
        updateDeviceState: updateDeviceState
    };
}
// Create the context
var CinemedicInUnrealContext = createContext(undefined);
// Create the provider
export var CinemedicInUnrealProvider = function (_a) {
    var children = _a.children;
    var supervisorData = useCinemedicInUnrealHook_Private();
    return (_jsx(CinemedicInUnrealContext.Provider, __assign({ value: supervisorData }, { children: children })));
};
export var useCinemedicInUnreal = function () {
    var context = useContext(CinemedicInUnrealContext);
    if (!context) {
        throw new Error("useCinemedicInUnreal must be used within a CinemedicInUnrealProvider");
    }
    return context;
};
