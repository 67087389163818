var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useSession } from "../../Sessions/SessionContext";
import React, { useRef } from "react";
import isEqual from 'lodash/isEqual';
import useDynamicVirtualPatient from "../../DemoMode/DynamicVirtualPatientStore";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { toast } from "react-hot-toast";
import { useInterpolationManagementHook } from "./useInterpolationManagementHook";
import { DefaultBloodPressureResultHistory, DefaultHybridControlData } from "./CinemedicSessionData";
import VirtualPatientStateStorage from "../../../components/Database/VirtualPatientStateStorage";
var INITIAL_STATE_NAME = "Initial Presentation";
export function useSupervisorDataHook_Private() {
    var _this = this;
    var _a = useSession(), rawSessionData = _a.rawSessionData, writeRawDataToSession = _a.writeRawDataToSession, sendMessage = _a.sendMessage, subscribeToMessages = _a.subscribeToMessages;
    var interpolatedState = useDynamicVirtualPatient().interpolatedState;
    var setStates = useDynamicVirtualPatient.getState().setStates;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _b = useVirtualPatientStore.getState(), updateVirtualPatientState = _b.updateVirtualPatientState, setVirtualPatientState = _b.setVirtualPatientState;
    var rawSessionDataRef = useRef(rawSessionData);
    var _c = React.useState(undefined), cinemedicSessionData = _c[0], setCinemedicSessionData = _c[1];
    var cinemedicSessionDataRef = useRef(cinemedicSessionData);
    var _d = React.useState(false), hasInitializedFromRemoteData = _d[0], setHasInitializedFromRemoteData = _d[1];
    var initializedFromRemoteDataRef = useRef(hasInitializedFromRemoteData);
    // const [patientStateForReset, setPatientStateForReset] = React.useState<VirtualPatientState | null>(null);
    var _e = React.useState(INITIAL_STATE_NAME), currentStateName = _e[0], setCurrentStateName = _e[1];
    var _f = useInterpolationManagementHook(setCurrentStateName), activeInterpolationData = _f.activeInterpolationData, setActiveInterpolationData = _f.setActiveInterpolationData;
    var _g = React.useState(undefined), docIdForReset = _g[0], setDocIdForReset = _g[1];
    React.useEffect(function () {
        initializedFromRemoteDataRef.current = hasInitializedFromRemoteData;
    }, [hasInitializedFromRemoteData]);
    React.useEffect(function () {
        cinemedicSessionDataRef.current = cinemedicSessionData;
    }, [cinemedicSessionData]);
    React.useEffect(function () {
        if (!rawSessionData || !rawSessionData.data || !rawSessionData.data.virtualPatientState) {
            return;
        }
        if (isEqual(rawSessionDataRef.current, rawSessionData)) {
            return;
        }
        rawSessionDataRef.current = rawSessionData;
        var newCinemedicSessionData = rawSessionData.data;
        console.log("Updating cinemedic session data from raw session data", newCinemedicSessionData);
        setCinemedicSessionData(newCinemedicSessionData);
        var remoteVirtualPatientState = rawSessionData.data.virtualPatientState;
        var localVirtualPatientState = useVirtualPatientStore.getState().virtualPatientState;
        if (!isEqual(remoteVirtualPatientState, localVirtualPatientState)) {
            setVirtualPatientState(remoteVirtualPatientState);
            console.log("Updating virtual patient state from raw session data", localVirtualPatientState, remoteVirtualPatientState);
        }
        setDocIdForReset(newCinemedicSessionData.rootDocId);
        if (!initializedFromRemoteDataRef.current) {
            setHasInitializedFromRemoteData(true);
            setCurrentStateName(rawSessionData.data.currentStateName);
        }
    }, [rawSessionData, setVirtualPatientState, setHasInitializedFromRemoteData, setStates, setCurrentStateName, setCinemedicSessionData, setDocIdForReset]);
    var writePatientMedicalStateToSession = React.useCallback(function (medicalState) {
        if (!rawSessionDataRef.current) {
            console.log("Can't write to session, no session data");
            return;
        }
        var newData = __assign(__assign({}, structuredClone(rawSessionDataRef.current.data)), { virtualPatientState: __assign(__assign({}, structuredClone(rawSessionDataRef.current.data.virtualPatientState)), { medicalState: structuredClone(medicalState) }) });
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            writeRawDataToSession(newData);
        }
    }, [writeRawDataToSession]);
    var setGameOverScreenActive = React.useCallback(function (active) {
        var _a, _b;
        if (!cinemedicSessionDataRef.current) {
            console.error("Can't set game over screen active, no session data");
            return;
        }
        if (!rawSessionDataRef.current) {
            return;
        }
        var oldActive = (_b = (_a = cinemedicSessionDataRef.current.systemControlState) === null || _a === void 0 ? void 0 : _a.gameOverScreenActive) !== null && _b !== void 0 ? _b : false;
        var newData = structuredClone(rawSessionDataRef.current.data);
        newData.systemControlState = __assign(__assign({}, (rawSessionDataRef.current.data.systemControlState || {})), { gameOverScreenActive: !oldActive });
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            writeRawDataToSession(newData);
        }
    }, [writeRawDataToSession]);
    var loadPatient = React.useCallback(function (state, showToast) {
        if (showToast === void 0) { showToast = true; }
        // setPatientStateForReset(structuredClone(state.virtualPatientState));
        setDocIdForReset(state.id);
        setVirtualPatientState(structuredClone(state.virtualPatientState));
        setCurrentStateName(INITIAL_STATE_NAME);
        if (rawSessionDataRef.current) {
            var newData = {
                docId: state.id,
                rootDocId: state.id,
                virtualPatientState: structuredClone(state.virtualPatientState),
                currentStateName: INITIAL_STATE_NAME,
                hybridControlData: DefaultHybridControlData,
                bloodPressureHistory: state.bloodPressureHistory || DefaultBloodPressureResultHistory,
                systemControlState: {
                    gameOverScreenActive: false
                }
            };
            if (!isEqual(rawSessionDataRef.current.data, newData)) {
                writeRawDataToSession(newData);
            }
            setActiveInterpolationData(null);
        }
        if (showToast) {
            toast.success("Loaded new Patient: ".concat(state.virtualPatientState.name), {
                duration: 5000,
                position: "bottom-center",
            });
        }
    }, [setVirtualPatientState, setDocIdForReset, setCurrentStateName, writeRawDataToSession, setActiveInterpolationData]);
    var resetPatient = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var loadedPatientState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!docIdForReset) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, VirtualPatientStateStorage.getPreset(docIdForReset).then(function (state) {
                            if (!state)
                                return false;
                            loadPatient(state, false);
                            return true;
                        })];
                case 1:
                    loadedPatientState = _a.sent();
                    if (loadedPatientState) {
                        toast.success("Patient successfully reset to defaults.", {
                            duration: 5000,
                            position: "bottom-center",
                        });
                    }
                    else {
                        toast.error("Failed to reset patient :(", {
                            duration: 5000,
                            position: "bottom-center",
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [docIdForReset, loadPatient]);
    var playSpeech = React.useCallback(function (speechId) {
        sendMessage("UE5_PlaySpeech", { speechId: speechId });
    }, [sendMessage]);
    var updateHybridControlData = React.useCallback(function (updater) {
        if (!cinemedicSessionData) {
            console.error("Can't update hybrid control data, no session data");
            return;
        }
        var oldData = structuredClone(cinemedicSessionData.hybridControlData || {});
        var newHybridControlData = updater(oldData);
        // setCinemedicSessionData({
        //     ...cinemedicSessionData,
        //     hybridControlData: newHybridControlData
        // });
        if (!rawSessionDataRef.current) {
            return;
        }
        var newData = structuredClone(rawSessionDataRef.current.data);
        newData.hybridControlData = newHybridControlData;
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            writeRawDataToSession(newData);
        }
    }, [cinemedicSessionData, writeRawDataToSession]);
    var addNewBloodPressureResult = React.useCallback(function (result) {
        var _a;
        var currentHistory = ((_a = cinemedicSessionDataRef.current) === null || _a === void 0 ? void 0 : _a.bloodPressureHistory) || { results: [] };
        var newHistory = {
            results: __spreadArray([result], currentHistory.results, true)
        };
        if (newHistory.results.length > 5) {
            newHistory.results = newHistory.results.slice(0, 5 + 1);
        }
        setCinemedicSessionData(__assign(__assign({}, cinemedicSessionDataRef.current), { bloodPressureHistory: newHistory }));
        if (!rawSessionDataRef.current) {
            return;
        }
        var newData = structuredClone(rawSessionDataRef.current.data);
        newData.bloodPressureHistory = newHistory;
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            writeRawDataToSession(newData);
        }
    }, [setCinemedicSessionData, writeRawDataToSession]);
    // Update the main virtual patient state when the interpolated state changes
    React.useEffect(function () {
        if (!interpolatedState) {
            return;
        }
        updateVirtualPatientState(function (state) {
            state.medicalState = structuredClone(interpolatedState);
        });
    }, [interpolatedState, updateVirtualPatientState]);
    // Write the virtual patient state to the session when it changes
    React.useEffect(function () {
        if (!rawSessionDataRef.current) {
            return;
        }
        if (!virtualPatientState) {
            return;
        }
        var newData = structuredClone(rawSessionDataRef.current.data);
        var newVirtualPatientState = structuredClone(virtualPatientState);
        newData.virtualPatientState = newVirtualPatientState;
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            console.log("Writing virtual patient state to session", virtualPatientState);
            writeRawDataToSession(newData);
        }
    }, [virtualPatientState, writeRawDataToSession]);
    React.useEffect(function () {
        if (!rawSessionDataRef.current) {
            return;
        }
        var newData = structuredClone(rawSessionDataRef.current.data);
        newData.currentStateName = currentStateName;
        if (!isEqual(rawSessionDataRef.current.data, newData)) {
            writeRawDataToSession(newData);
        }
    }, [currentStateName, writeRawDataToSession]);
    React.useEffect(function () {
        var handleMessage = function (message) {
        };
        // Register the callback and get the unregister function
        var unregister = subscribeToMessages(handleMessage);
        // Automatically unregister the callback when the component unmounts
        return function () {
            unregister();
        };
    }, [subscribeToMessages]);
    return {
        writePatientMedicalStateToSession: writePatientMedicalStateToSession,
        isReady: hasInitializedFromRemoteData,
        loadNewPatient: loadPatient,
        resetPatient: resetPatient,
        canReset: !!docIdForReset,
        activeInterpolationData: activeInterpolationData,
        setActiveInterpolationData: setActiveInterpolationData,
        currentStateName: currentStateName,
        setCurrentStateName: setCurrentStateName,
        cinemedicSessionData: cinemedicSessionData,
        playSpeech: playSpeech,
        updateHybridControlData: updateHybridControlData,
        addNewBloodPressureResult: addNewBloodPressureResult,
        setGameOverScreenActive: setGameOverScreenActive
    };
}
