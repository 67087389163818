var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { motion } from "framer-motion";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var DevicesButton = function (_a) {
    var label = _a.label, isActive = _a.active, onActiveChange = _a.onActiveChange;
    var _b = React.useState(0), progress = _b[0], setProgress = _b[1];
    var _c = React.useState(null), timerHandle = _c[0], setTimerHandle = _c[1];
    React.useEffect(function () {
        if (isActive) {
            setProgress(100);
        }
        else {
            setProgress(0);
        }
    }, [isActive]);
    var handleButtonClick = function () {
        if (timerHandle !== null)
            return; // Prevent multiple activations
        var target = 100;
        var step = 1;
        if (isActive) {
            target = 0;
            step = -1;
        }
        // setProgress(100 - target);
        onActiveChange(target === 0);
        // Simulate progress over 5 seconds
        var newInterval = setInterval(function () {
            setProgress(function (prev) {
                var next = prev + step;
                if (next === target) {
                    clearInterval(newInterval);
                    setTimerHandle(null);
                    setTimeout(function () {
                        onActiveChange(target === 100);
                    }, 0);
                }
                return next;
            });
        }, 50);
        setTimerHandle(newInterval);
    };
    return (_jsxs("button", __assign({ onClick: handleButtonClick, className: "bg-gray-100 relative rounded px-4 py-2 border text-sm flex items-center justify-between shadow active:shadow-inner overflow-hidden disabled:pointer-events-none", disabled: timerHandle !== null }, { children: [_jsx(motion.div, { className: "absolute left-0 top-0 bottom-0 ".concat(timerHandle === null ? "bg-green-100" : "bg-blue-100"), initial: { width: "0%" }, animate: { width: "".concat(progress, "%") }, transition: { duration: 0.05, ease: "linear" } }), _jsx("span", __assign({ className: "relative z-10 text-left" }, { children: label })), _jsx("span", __assign({ className: "relative z-10 text-xs" }, { children: timerHandle ?
                    _jsx("span", __assign({ className: "text-blue-500" }, { children: "Working..." }))
                    :
                        _jsx(_Fragment, { children: isActive ? (_jsx("span", __assign({ className: "text-green-500" }, { children: "Active" }))) : (_jsx("span", __assign({ className: "text-red-500" }, { children: "Inactive" }))) }) }))] })));
};
var NIBPStatusIndicator = function (_a) {
    var deviceStatus = _a.deviceStatus;
    var isConnected = deviceStatus.nibpLeftArm || deviceStatus.nibpRightArm;
    return (_jsxs("button", __assign({ className: "bg-gray-100 relative rounded px-4 py-2 border text-sm flex items-center justify-between overflow-hidden disabled:pointer-events-none", disabled: true }, { children: [isConnected && (_jsx("div", { className: "absolute left-0 top-0 bottom-0 right-0 bg-green-100" })), _jsx("span", __assign({ className: "relative z-10 text-left" }, { children: "NIBP Cuff" })), _jsx("span", __assign({ className: "relative z-10 text-xs" }, { children: isConnected ? (_jsx("span", __assign({ className: "text-green-500" }, { children: "Connected" }))) : (_jsx("span", __assign({ className: "text-red-500" }, { children: "Disconnected" }))) }))] })));
};
var DevicesSection = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var updateVirtualPatientState = useVirtualPatientStore.getState().updateVirtualPatientState;
    var handleECGChange = React.useCallback(function (newActive) {
        updateVirtualPatientState(function (state) { return state.deviceStatus.ecg = newActive; });
    }, [updateVirtualPatientState]);
    var handlePulseOximeterChange = React.useCallback(function (newActive) {
        updateVirtualPatientState(function (state) { return state.deviceStatus.pulseOximeterLeftFoot = newActive; });
    }, [updateVirtualPatientState]);
    var handleIVChange = React.useCallback(function (newActive) {
        updateVirtualPatientState(function (state) { return state.deviceStatus.ivCatheterLeftArm = newActive; });
    }, [updateVirtualPatientState]);
    var handleFaceMaskChange = React.useCallback(function (newActive) {
        updateVirtualPatientState(function (state) { return state.deviceStatus.faceMask = newActive; });
    }, [updateVirtualPatientState]);
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-between" }, { children: _jsx("h2", __assign({ className: "text-base font-semibold text-gray-800" }, { children: "Devices" })) })), _jsx("div", __assign({ className: "mt-3 space-y-3" }, { children: _jsxs("div", __assign({ className: "grid grid-cols-2 gap-3" }, { children: [_jsx(DevicesButton, { label: "ECG", active: virtualPatientState.deviceStatus.ecg, onActiveChange: handleECGChange }), _jsx(DevicesButton, { label: "Pulse Oximeter", active: virtualPatientState.deviceStatus.pulseOximeterLeftFoot, onActiveChange: handlePulseOximeterChange }), _jsx(DevicesButton, { label: "IV", active: virtualPatientState.deviceStatus.ivCatheterLeftArm, onActiveChange: handleIVChange }), _jsx(DevicesButton, { label: "Face Mask", active: virtualPatientState.deviceStatus.faceMask, onActiveChange: handleFaceMaskChange }), _jsx(NIBPStatusIndicator, { deviceStatus: virtualPatientState.deviceStatus })] })) }))] })));
};
export default DevicesSection;
